'use client';

import { FC, useEffect, useState } from 'react';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import {
  SearchParamsType,
  searchParamsAtom,
  searchParamsValueAtom,
} from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { isWindowDefined } from '@core/Constants/Window';
import { filterObjectProperties } from '@core/Utils/Object/FilterObjectProperties';
import { experimentsAtom } from '@growthBookExperimentation/Atoms/Experiments.atom';
import { FlagKey } from '@growthBookExperimentation/Entities/FlagKeys.entities';
import { trackPageView } from '@snowplow/browser-tracker';

import { pageviewExperimentAtom } from '@tracking/Atoms/PageviewExperiment.atom';
import { TrackingEvent } from '@tracking/Entities/Schema.entity';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';
import { getExperimentViewedEvents } from '@tracking/Utils/GetExperimentViewedEvents';
import { getSchemaByRef } from '@tracking/Utils/GetSchemaByRef';
import { validateSchema } from '@tracking/Utils/ValidateSchema';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import hash from 'stable-hash';
import { EventViewerTrigger } from '../EventViewer/EventViewerModal';

const marketingIdsToCheck: (keyof SearchParamsType)[] = [
  'fbc',
  'fbclid',
  'gclid',
  'msclkid',
  'ttclid',
  'ad_id',
  'OutbrainClickId',
  'ScCid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'cItemId',
  'msclkId',
];

export const TrackPage: FC<{
  context?: TrackingEvent[];
  searchParams?: SearchParamsType;
}> = ({ context = [], searchParams }) => {
  const [hasTrackViewPage, setTrackViewPage] = useState(false);
  const [pageviewExperiment, setPageviewExperiment] = useAtom(pageviewExperimentAtom);
  const experiments = useAtomValue(experimentsAtom);

  const searchParamsValues = useSetAtom(searchParamsValueAtom);
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const searchParamsAtomValue = useAtomValue(searchParamsAtom);

  const isDesktop = isWindowDefined && window.innerWidth > 1023;

  let newContext = [...context];
  const expIds: FlagKey[] = [
    'natural-language-search',
    'navigation-redesign-experiment',
    'sort-order-v2-experiment',
    'sort-order-v3-experiment',
    ...(isDesktop ? ['natural-language-search-desktop-experiment' as const] : []),
  ];

  useEffect(() => {
    const routeSearchParams = filterObjectProperties(
      searchParams as SearchParamsType,
      marketingIdsToCheck,
    );
    const mergedObject = { ...searchParamsAtomValue, ...routeSearchParams };
    searchParamsValues(mergedObject);
  }, [
    hash(searchParamsAtom),
    hash(filterObjectProperties(searchParams as SearchParamsType, marketingIdsToCheck)),
  ]);

  useEffect(() => {
    if (searchParamsAtomValue) {
      setTrackViewPage(true);
    }
  }, [searchParamsAtomValue]);

  useEffect(() => {
    if (hasTrackViewPage) {
      const marketingDataObj = {
        marketingValues: { ...searchParamsAtomValue },
      };
      const marketingEvent = marketingToEvent(marketingDataObj);
      const user = {
        userId,
        values: { ...formState },
      };
      const userEvent = userContextToEvent(user);

      // If the user has not seen the experiment, add the experiment viewed events to the context
      if (!pageviewExperiment) {
        const experimentViewedEvents = getExperimentViewedEvents(expIds, experiments);
        const contextWithExp = [...context, ...experimentViewedEvents];
        setPageviewExperiment([
          'natural-language-search',
          ...(isDesktop ? ['natural-language-search-desktop-experiment' as const] : []),
          'navigation-redesign-experiment',
        ]);
        newContext = [...contextWithExp, userEvent, marketingEvent];
      } else {
        newContext = [...context, userEvent, marketingEvent];
      }

      newContext?.map(event => validateSchema(getSchemaByRef(event.schema), event));
      trackPageView({ context: newContext });

      const eventObject = { event: 'pageview', data: newContext };

      // @ts-expect-error - window typing
      if (isWindowDefined) (window.dataLayer ||= []).push(eventObject);

      const correctEventObject = {
        event: 'pageview',
        data: {},
        context: newContext,
        hash: Date.now().toString(36),
      };
      // @ts-expect-error - window typing
      if (isWindowDefined) (window.eventHistory ||= []).push(correctEventObject);

      EventViewerTrigger?.forceUpdate?.();
    }
  }, [hasTrackViewPage]);

  if (typeof window === 'undefined') return null;
};
